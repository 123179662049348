import { NgModule } from '@angular/core';
import { NavDrawerComponent } from './components/nav-drawer/nav-drawer.component';
import { RouterModule } from '@angular/router';
import { CardComponent } from './components/card/card.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { SearchPipe } from './pipes/search.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { FilterSidebarComponent } from './components/filter-sidebar/filter-sidebar.component';
import { FilterBoxComponent } from './components/filter-box/filter-box.component';
import { MasterViewComponent } from './components/master-view/master-view.component';
import { DetailsViewComponent } from './components/details-view/details-view.component';
import { LoadingViewComponent } from './components/loading-view/loading-view.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { CardMenuComponent } from './components/card-menu/card-menu.component';
import { CardMenuItemComponent } from './components/card-menu-item/card-menu-item.component';
import { InoElementsModule } from '@externals/inovex-elements.module';
import { CommonModule, DatePipe } from '@angular/common';
import { VerticalTabBarComponent } from './components/vertical-tab-bar/vertical-tab-bar.component';
import { TabContentComponent } from './components/tab-content/tab-content.component';
import { ScrollToTopButtonComponent } from './components/scroll-to-top-button/scroll-to-top-button.component';
import { ObserversModule } from '@angular/cdk/observers';
import { DetailsViewHeaderComponent } from './components/details-view/partials/details-view-header/details-view-header.component';
import { DetailsViewContentComponent } from './components/details-view/partials/details-view-content/details-view-content.component';
import { DetailHeaderComponent } from './components/header/detail-header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ChipSelectionComponent } from './components/selections/chip-selection/chip-selection.component';
import { ChipLabelSetComponent } from './components/chip-label-set/chip-label-set.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogButtonsComponent } from './components/dialog-buttons/dialog-buttons.component';
import { SelectionComponent } from './components/selections/selection/selection.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AddButtonComponent } from './components/add-button/add-button.component';
import { IframeComponent } from './components/iframe/iframe.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { TimelineEntryDirective } from './directives/timeline-entry.directive';
import { SortAlphabeticallyPipe } from './pipes/sort-alphabetically.pipe';
import { StringSelectionComponent } from './components/string-selection/string-selection.component';
import { ParseDatePipe } from './pipes/parse-date.pipe';
import { EmployeeNamePipe } from './pipes/employee-name.pipe';
import { SkillNamesPipe } from './pipes/skill-names.pipe';
import { SelectionListComponent } from './components/selections/selection/selection-list/selection-list.component';
import { IsSelectedPipe } from './components/selections/pipes/is-selected.pipe';
import { ToArrayPipe } from './components/selections/pipes/to-array.pipe';
import { ToSelectionOptionPipe } from './pipes/to-selection-option.pipe';
import { ToSelectedItemsPipe } from './components/selections/pipes/to-selected-items.pipe';
import { SelectionListKeyHandlerDirective } from './components/selections/selection/selection-list/selection-list-key-handler.directive';
import { ToSelectionOptionByFnPipe } from './pipes/to-selection-option-by-fn.pipe';
import { HasExactMatchPipe } from './pipes/has-exact-match.pipe';
import { FilterCardComponent } from '@shared/components/filter-card/filter-card.component';
import { FilterCardReadComponent } from './components/filter-card-read/filter-card-read.component';
import { IncludesPipe } from './pipes/includes.pipe';
import { ListViewComponent } from './components/list-view/list-view.component';
import { ListViewSearchInputComponent } from './components/list-view/list-view-search-input/list-view-search-input.component';
import { ListViewResultCountComponent } from './components/list-view/list-view-result-count/list-view-result-count.component';
import { ListViewSortInputComponent } from './components/list-view/list-view-sort-input/list-view-sort-input.component';
import { ListViewFilterChipsComponent } from './components/list-view/list-view-filter-chips/list-view-filter-chips.component';
import { SortFieldToLabelValuePipe } from './pipes/sort-field-to-label-value.pipe';
import { ListViewNoResultTextComponent } from '@shared/components/list-view/list-view-no-result-text/list-view-no-result-text.component';
import { ListViewLoadingSpinnerComponent } from './components/list-view/list-view-loading-spinner/list-view-loading-spinner.component';
import { ListViewCardsContainerComponent } from './components/list-view/list-view-cards-container/list-view-cards-container.component';
import { ListViewTopBarContainerComponent } from '@shared/components/list-view/list-view-top-bar-container/list-view-top-bar-container.component';
import { DistanceToNowPipe } from './pipes/distance-to-now.pipe';
import { CustomerNamePipe } from './pipes/customer-name.pipe';
import { SavedFilterListComponent } from './components/filter-sidebar/saved-filter-list/saved-filter-list.component';
import { SavedFilterQuickSelectComponent } from './components/filter-sidebar/saved-filter-quick-select/saved-filter-quick-select.component';
import { DropdownSelectionComponent } from './components/selections/dropdown-selection/dropdown-selection.component';
import { FilterSidebarFooterComponent } from './components/filter-sidebar/filter-sidebar-footer/filter-sidebar-footer.component';
import { FilterSidebarToggleButtonComponent } from './components/filter-sidebar/filter-sidebar-toggle-button/filter-sidebar-toggle-button.component';
import { FilterSidebarTabBarComponent } from './components/filter-sidebar/filter-sidebar-tab-bar/filter-sidebar-tab-bar.component';
import { InmaSharedModule } from '@inovex-inma/shared-angular';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { I18NextModule } from 'angular-i18next';
import { DetailItemComponent } from './components/detail-item/detail-item.component';
import { ChipComponent } from './components/chip/chip.component';
import { CircleSpinnerComponent } from './components/circle-spinner/circle-spinner.component';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { TimelineBlockComponent } from './components/timeline/timeline-block/timeline-block.component';
import { TimelinePopoverComponent } from './components/timeline/timeline-popover/timeline-popover.component';
import { RangeContainerComponent } from './components/range-container/range-container.component';
import { VarDirective } from '@shared/directives/ng-var.directive';
import { BoxStatusIndicatorComponent } from '@shared/components/box-status-indicator/box-status-indicator.component';
import { DateRangePipe } from './pipes/date-range.pipe';
import { TimelineFocusActionComponent } from '@shared/components/timeline/timeline-focus-action/timeline-focus-action.component';
import { FlyInAnchorDirective } from './directives/fly-in-anchor.directive';
import { FlyInComponent } from './components/fly-in/fly-in.component';
import { ActionButtonComponent } from '@shared/components/action-button/action-button.component';
import { ExpandToggleButtonComponent } from './components/expand-toggle-button/expand-toggle-button/expand-toggle-button.component';
import { IsNanPipe } from './pipes/is-nan.pipe';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { ToProfileUrlPipe } from './pipes/to-profile-url.pipe';
import { EmployeeNameComponent } from './components/employee-name/employee-name.component';
import { RatedSkillInputComponent } from './components/rated-skill-input/rated-skill-input.component';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { SkillRatingComponent } from './components/skill-rating/skill-rating.component';
import { DatesEqualPipe } from './pipes/dates-equal.pipe';
import { SkillRatingInputComponent } from './components/skill-rating-input/skill-rating-input.component';
import { ToArrayOfLengthPipe } from './pipes/to-array-of-length.pipe';
import { SkillRatingDotComponent } from './components/skill-rating-dot/skill-rating-dot.component';
import { DialogService } from '@shared/services/dialog.service';
import { SessionExpiredDialogComponent } from './components/session-expired-dialog/session-expired-dialog.component';
import { NewVersionDialogComponent } from './components/new-version-dialog/new-version-dialog.component';
import { AvatarPlaceholderComponent } from './components/avatar-placeholder/avatar-placeholder.component';
import { AvatarSelectionSingleComponent } from './components/avatar-selection-single/avatar-selection-single.component';
import { AvatarSelectionMultiComponent } from './components/avatar-selection-multi/avatar-selection-multi.component';
import { PickPipe } from './pipes/pick.pipe';
import { AvatarComponent } from './components/avatar/avatar.component';
import { AvatarSelectionBaseDirective } from './components/avatar-selection-base/avatar-selection-base.directive';
import { AssignmentTableComponent } from './components/assignment-table/assignment-table.component';
import { AssignmentTableCellEmployeeComponent } from '@shared/components/assignment-table/assignment-table-cell-employee/assignment-table-cell-employee.component';
import { FteHpwBlockComponent } from '@shared/components/fte-hpw-block/fte-hpw-block.component';
import { AssignmentTableCellUtilizationComponent } from './components/assignment-table/assignment-table-cell-utilization/assignment-table-cell-utilization.component';
import { DateShortPipe } from './pipes/date-short.pipe';
import { CurrencyPerPipe } from './pipes/currencyPer.pipe';
import { ForecastStatusMessagePipe } from './pipes/forecast-status-message.pipe';

import { WithFallbackValuePipe } from './pipes/with-fallback-value.pipe';
import { BillingTypeLabelPipe } from './pipes/billing-type-label.pipe';
import { TimelineAssignmentIntervalComponent } from './components/timeline/timeline-assignment-interval/timeline-assignment-interval.component';
import { TimelineLinkedEntityComponent } from './components/timeline/timeline-linked-entity/timeline-linked-entity.component';
import { AssignmentToPopoverEntriesPipe } from '@shared/components/timeline/assignment-to-popover-entries.pipe';
import { FabSetComponent } from './components/fab-set/fab-set.component';
import { FabComponent } from './components/fab/fab.component';
import { EqualHintComponent } from '@shared/components/opportunity-proposal-equal-hint/equal-hint.component';
import { MetaLinkComponent } from './components/meta-link/meta-link.component';
import { EmploymentStatusNamePipe } from './pipes/employment-status-name.pipe';
import { EntityMatchIdentifierPipe } from './pipes/entity-match-identifier.pipe';
import { NotFoundErrorComponent } from './components/not-found-error/not-found-error.component';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { HeadlineComponent } from './components/headline/headline.component';
import { ChipGroupComponent } from './components/chip-group/chip-group.component';
import { FormatedListPipe } from './pipes/formated-list.pipe';
import { ToggleIconButtonComponent } from './components/toggle-icon-button/toggle-icon-button.component';
import { ToDetailStatusPipe } from './pipes/to-detail-status.pipe';
import { FilterNodeService } from '@shared/services/filter-node.service';
import { FilterCriteriaComponent } from '@shared/components/advanced-filter/filter-criteria/filter-criteria.component';
import { FilterNodeComponent } from '@shared/components/advanced-filter/filter-node/filter-node.component';
import { TextFilterCriteriaComponent } from './components/advanced-filter/filter-criteria/text-filter-criteria/text-filter-criteria.component';
import { SelectFilterCriteriaComponent } from './components/advanced-filter/filter-criteria/select-filter-criteria/select-filter-criteria.component';
import { NumberFilterCriteriaComponent } from './components/advanced-filter/filter-criteria/number-filter-criteria/number-filter-criteria.component';
import { DateFilterCriteriaComponent } from './components/advanced-filter/filter-criteria/date-filter-criteria/date-filter-criteria.component';
import { SwitchMultiCasePipe } from '@shared/pipes/switch-multi-case.pipe';
import { QueryParamService } from './services/query-param.service';
import { AvailabilityCriteriaComponent } from './components/advanced-filter/filter-criteria/availability-filter-criteria/availability-filter-criteria.component';
import { EmployeeStatusIconComponent } from './components/employee-status-icon/employee-status-icon.component';
import { EmployeeAvailabilityBarComponent } from '@shared/components/employee-availability-bar/employee-availability-bar.component';
import { HeadlineInfoBoxComponent } from '@shared/components/headline-info-box/headline-info-box.component';
import { NumberToStringDirective } from './directives/number-to-string-input.directive';
import { ClassificationComponent } from './components/classification/classification.component';
import { EmployeeAvatarComponent } from './components/employee-avatar/employee-avatar.component';
import { EmployeeAvatarStackComponent } from './components/employee-avatar-stack/employee-avatar-stack.component';
import { EmployeeProfileIconComponent } from './components/employee-profile-icon/employee-profile-icon.component';
import { CrmPropertyCheckPipe } from './pipes/crm-check-pipe';
import { ProjectControllingComponent } from './components/project-controlling/project-controlling.component';

@NgModule({
  declarations: [
    NavDrawerComponent,
    CardComponent,
    DetailsViewComponent,
    ClickOutsideDirective,
    SearchPipe,
    SortPipe,
    FilterSidebarComponent,
    FilterBoxComponent,
    MasterViewComponent,
    LoadingViewComponent,
    LoadingBarComponent,
    CardMenuComponent,
    CardMenuItemComponent,
    VerticalTabBarComponent,
    TabContentComponent,
    ScrollToTopButtonComponent,
    DetailsViewHeaderComponent,
    DetailsViewContentComponent,
    DetailHeaderComponent,
    NotificationsComponent,
    ChipSelectionComponent,
    ChipLabelSetComponent,
    DialogComponent,
    DialogButtonsComponent,
    SelectionComponent,
    AddButtonComponent,
    IframeComponent,
    TimelineComponent,
    TimelineEntryDirective,
    SortAlphabeticallyPipe,
    StringSelectionComponent,
    ParseDatePipe,
    EmployeeNamePipe,
    SkillNamesPipe,
    SelectionListComponent,
    IsSelectedPipe,
    ToSelectionOptionPipe,
    ToArrayPipe,
    ToSelectedItemsPipe,
    SelectionListKeyHandlerDirective,
    ToSelectionOptionByFnPipe,
    HasExactMatchPipe,
    FilterCardComponent,
    FilterCardReadComponent,
    IncludesPipe,
    ListViewComponent,
    ListViewSearchInputComponent,
    ListViewResultCountComponent,
    ListViewSortInputComponent,
    ListViewFilterChipsComponent,
    ListViewNoResultTextComponent,
    SortFieldToLabelValuePipe,
    ListViewLoadingSpinnerComponent,
    ListViewCardsContainerComponent,
    ListViewTopBarContainerComponent,
    DistanceToNowPipe,
    CustomerNamePipe,
    SavedFilterQuickSelectComponent,
    DropdownSelectionComponent,
    SavedFilterListComponent,
    FilterSidebarFooterComponent,
    FilterSidebarToggleButtonComponent,
    FilterSidebarTabBarComponent,
    MonthPickerComponent,
    DetailItemComponent,
    ChipComponent,
    CircleSpinnerComponent,
    ClickStopPropagationDirective,
    TimelineBlockComponent,
    TimelinePopoverComponent,
    RangeContainerComponent,
    VarDirective,
    BoxStatusIndicatorComponent,
    DateRangePipe,
    TimelineFocusActionComponent,
    ExpandToggleButtonComponent,
    FlyInAnchorDirective,
    FlyInComponent,
    ActionButtonComponent,
    ToProfileUrlPipe,
    EmployeeNameComponent,
    ToDetailStatusPipe,
    CrmPropertyCheckPipe,
    IsNanPipe,
    DatepickerComponent,
    DatesEqualPipe,
    RatedSkillInputComponent,
    SkillRatingComponent,
    SkillRatingInputComponent,
    ToArrayOfLengthPipe,
    SkillRatingDotComponent,
    SessionExpiredDialogComponent,
    NewVersionDialogComponent,
    AvatarSelectionSingleComponent,
    AvatarPlaceholderComponent,

    AvatarSelectionMultiComponent,
    PickPipe,
    AvatarComponent,
    AvatarSelectionBaseDirective,
    AssignmentTableComponent,
    AssignmentTableCellEmployeeComponent,
    FteHpwBlockComponent,
    AssignmentTableCellUtilizationComponent,
    DateShortPipe,
    CurrencyPerPipe,
    WithFallbackValuePipe,
    BillingTypeLabelPipe,
    ForecastStatusMessagePipe,
    WithFallbackValuePipe,
    BillingTypeLabelPipe,
    TimelineAssignmentIntervalComponent,
    TimelineLinkedEntityComponent,
    AssignmentToPopoverEntriesPipe,
    FabSetComponent,
    FabComponent,
    EqualHintComponent,
    MetaLinkComponent,
    EmploymentStatusNamePipe,
    EntityMatchIdentifierPipe,
    NotFoundErrorComponent,
    EnumToArrayPipe,
    HeadlineComponent,
    ChipGroupComponent,
    FormatedListPipe,
    ToggleIconButtonComponent,
    FilterCriteriaComponent,
    FilterNodeComponent,
    TextFilterCriteriaComponent,
    SelectFilterCriteriaComponent,
    NumberFilterCriteriaComponent,
    DateFilterCriteriaComponent,
    AvailabilityCriteriaComponent,
    SwitchMultiCasePipe,
    EmployeeStatusIconComponent,
    HeadlineInfoBoxComponent,
    EmployeeAvailabilityBarComponent,
    NumberToStringDirective,
    ClassificationComponent,
    EmployeeAvatarComponent,
    EmployeeAvatarStackComponent,
    EmployeeProfileIconComponent,
    ProjectControllingComponent,
  ],
  imports: [
    CommonModule,
    InoElementsModule,
    InmaSharedModule,
    I18NextModule,
    RouterModule,
    ObserversModule,
    ReactiveFormsModule,
    ScrollingModule,
    NgbRatingModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    ClickOutsideDirective,
    DetailsViewComponent,
    NavDrawerComponent,
    CardComponent,
    SearchPipe,
    SortPipe,
    FilterSidebarComponent,
    FilterBoxComponent,
    MasterViewComponent,
    LoadingViewComponent,
    CardMenuItemComponent,
    VerticalTabBarComponent,
    TabContentComponent,
    ScrollToTopButtonComponent,
    DetailHeaderComponent,
    DetailsViewHeaderComponent,
    DetailsViewContentComponent,
    NotificationsComponent,
    ChipSelectionComponent,
    ChipLabelSetComponent,
    DialogComponent,
    DialogButtonsComponent,
    SelectionComponent,
    AddButtonComponent,
    IframeComponent,
    TimelineComponent,
    TimelineEntryDirective,
    SortAlphabeticallyPipe,
    StringSelectionComponent,
    ParseDatePipe,
    EmployeeNamePipe,
    SkillNamesPipe,
    ToSelectionOptionPipe,
    SelectionListKeyHandlerDirective,
    ToSelectionOptionByFnPipe,
    FilterCardReadComponent,
    IncludesPipe,
    ListViewComponent,
    ListViewSearchInputComponent,
    ListViewResultCountComponent,
    ListViewSortInputComponent,
    ListViewFilterChipsComponent,
    ListViewNoResultTextComponent,
    SortFieldToLabelValuePipe,
    ListViewLoadingSpinnerComponent,
    ListViewCardsContainerComponent,
    ListViewTopBarContainerComponent,
    DistanceToNowPipe,
    MonthPickerComponent,
    DetailItemComponent,
    ChipComponent,
    ClickStopPropagationDirective,
    TimelineBlockComponent,
    TimelinePopoverComponent,
    RangeContainerComponent,
    VarDirective,
    BoxStatusIndicatorComponent,
    DateRangePipe,
    TimelineFocusActionComponent,
    ExpandToggleButtonComponent,
    FlyInAnchorDirective,
    FlyInComponent,
    ActionButtonComponent,
    EmployeeNameComponent,
    ToDetailStatusPipe,
    CrmPropertyCheckPipe,
    IsNanPipe,
    DatepickerComponent,
    DatesEqualPipe,
    RatedSkillInputComponent,
    SkillRatingComponent,
    SkillRatingInputComponent,
    SessionExpiredDialogComponent,
    AvatarPlaceholderComponent,
    NewVersionDialogComponent,

    AvatarSelectionSingleComponent,
    AvatarPlaceholderComponent,
    AvatarSelectionMultiComponent,
    PickPipe,
    AvatarComponent,
    AssignmentTableComponent,
    AssignmentTableCellEmployeeComponent,
    FteHpwBlockComponent,
    AssignmentTableCellUtilizationComponent,
    DateShortPipe,
    CurrencyPerPipe,
    WithFallbackValuePipe,
    BillingTypeLabelPipe,
    ForecastStatusMessagePipe,
    WithFallbackValuePipe,
    BillingTypeLabelPipe,
    TimelineAssignmentIntervalComponent,
    TimelineLinkedEntityComponent,
    ToProfileUrlPipe,
    AssignmentToPopoverEntriesPipe,
    FabSetComponent,
    FabComponent,
    EqualHintComponent,
    MetaLinkComponent,
    EmploymentStatusNamePipe,
    EntityMatchIdentifierPipe,
    EnumToArrayPipe,
    HeadlineComponent,
    ChipGroupComponent,
    FormatedListPipe,
    ToggleIconButtonComponent,
    FilterCriteriaComponent,
    FilterNodeComponent,
    TextFilterCriteriaComponent,
    SelectFilterCriteriaComponent,
    NumberFilterCriteriaComponent,
    DateFilterCriteriaComponent,
    AvailabilityCriteriaComponent,
    SwitchMultiCasePipe,
    EmployeeStatusIconComponent,
    HeadlineInfoBoxComponent,
    EmployeeAvailabilityBarComponent,
    ClassificationComponent,
    EmployeeAvatarComponent,
    EmployeeAvatarStackComponent,
    EmployeeProfileIconComponent,
    ProjectControllingComponent,
  ],
  providers: [
    EmployeeNamePipe,
    DistanceToNowPipe,
    CustomerNamePipe,
    DialogService,
    DatePipe,
    DateRangePipe,
    EmploymentStatusNamePipe,
    EntityMatchIdentifierPipe,
    FilterNodeService,
    QueryParamService,
    ToDetailStatusPipe,
    CrmPropertyCheckPipe,
    CurrencyPerPipe,
  ],
})
export class SharedModule {}
