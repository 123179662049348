import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, first, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { UserInfo, UserRole } from '@shared/models/types/user-info';
import { AccountQueryService } from './generated/account.query.service.generated';
import * as Sentry from '@sentry/angular-ivy';
import { throwGraphqlErrorsIfExists } from '@shared/utils/throw-graphql-errors-if-exists';
import { isProfileStale } from '@shared/utils/employee-profile';
import { NotificationService } from '@shared/services/notification.service';
import { APP_CONFIG } from 'src/app/configuration/configuration.module';
import { AppConfig } from '@shared/models/interfaces/app-config';
import { Team, EmploymentStatus } from '@shared/models/types/types.generated';
import { Employee } from '@employees/models/employee';
import { differenceInDays, differenceInMonths } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class UserService {
  public readonly user$: Observable<UserInfo>;

  private _user: BehaviorSubject<UserInfo>;

  constructor(
    private accountQueryService: AccountQueryService,
    private notificationService: NotificationService,
    @Inject(APP_CONFIG) private config: AppConfig,
  ) {
    this._user = new BehaviorSubject<UserInfo>(null);
    this.user$ = this._user.asObservable();
    this.user$.pipe(filter((user) => user !== null)).subscribe((user) => {
      Sentry.setUser({
        id: user.id,
        email: user.email,
        username: user.login,
      });
      this.notifyIfProfileIsStale(user);
    });
  }

  public fetchUserInfo = () =>
    this.accountQueryService.fetch().pipe(
      first(),
      throwGraphqlErrorsIfExists(),
      tap(({ data }) => this._user.next(data.account)),
    );

  public getUser = (): UserInfo => this._user.getValue();

  public hasRoles = (requiredRoles: UserRole[]): boolean => UserService.userHasRoles(this.getUser(), requiredRoles);

  public hasRoles$ = (requiredRoles: UserRole[]): Observable<boolean> =>
    this.user$.pipe(
      filter((user) => user != null),
      map((user) => UserService.userHasRoles(user, requiredRoles)),
    );

  private static userHasRoles(user: UserInfo, requiredRoles: UserRole[]): boolean {
    if (!user?.roles) return false;

    return requiredRoles.every((requiredRole) => user.roles.includes(requiredRole));
  }

  public hasAnyRole$ = (roles: UserRole[]): Observable<boolean> =>
    this.user$.pipe(
      filter((user) => user != null),
      map((user) => roles.some((role) => user?.roles.includes(role))),
    );

  public hasAnyRole = (roles: UserRole[]): boolean => {
    const user = this._user.getValue();

    if (!user?.roles) {
      return false;
    }

    return roles.some((role) => user?.roles.includes(role));
  };

  private notifyIfProfileIsStale(user: UserInfo) {
    if (user?.relatedEmployee) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { salesProfileGoogleDriveId, salesProfileLastModified, mostRelevantEmploymentPeriod } =
        user.relatedEmployee;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { employmentStatus, team, billable } = mostRelevantEmploymentPeriod || {};
      const isStale = isProfileStale({
        teamIds: this.config.employees.teams,
        operativIds: this.config.employees.employmentStatusGroups.operativIds,
        salesProfileGoogleDriveId: salesProfileGoogleDriveId as string,
        salesProfileLastModified: salesProfileLastModified && new Date(salesProfileLastModified as string),
        employmentStatus: employmentStatus as EmploymentStatus,
        mainTeam: Employee.getMainTeam(team as Team),
        isIntern: !billable as boolean,
      });

      if (isStale) {
        const STORAGE_KEY: string = 'STALE_PROFILE_LAST_NOTIFIED';
        const THRESHOLD_DAYS: number = 1;
        const currentTimestamp: number = new Date().getTime();
        const lastNotificationTimestamp: number = parseInt(localStorage.getItem(STORAGE_KEY), 10);

        if (
          !lastNotificationTimestamp ||
          differenceInDays(currentTimestamp, lastNotificationTimestamp) >= THRESHOLD_DAYS
        ) {
          this.notificationService.addWarningNotification('errors:errors.profile.isStale', {
            i18nOptions: {
              value: differenceInMonths(new Date(), new Date(salesProfileLastModified as Date)),
            },
            actionOptions: {
              action() {
                window.open(`https://docs.google.com/document/d/${salesProfileGoogleDriveId}`);
              },
              i18nKey: 'errors:errors.profile.action',
            },
          });
          localStorage.setItem(STORAGE_KEY, `${currentTimestamp}`);
        }
      }
    }
  }
}
