import { Team, EmploymentStatus, EmployeeAbsenceInterval } from '@shared/models/types/types.generated';
import { endOfDay, differenceInMonths, isWithinInterval, startOfDay } from 'date-fns';
import { Teams } from '@shared/models/interfaces/app-config';
import { parseDate } from './utils';

const THRESHOLD_MONTHS = 6;

type Params = {
  teamIds: Teams;
  operativIds: string[];
  salesProfileGoogleDriveId: string | null;
  salesProfileLastModified: Date | null;
  mainTeam: Pick<Team, 'id' | 'name' | 'shortName'>;
  employmentStatus: EmploymentStatus;
  isIntern: boolean;
};

export function isProfileStale(params: Params): boolean {
  const {
    teamIds,
    operativIds,
    salesProfileGoogleDriveId,
    salesProfileLastModified,
    mainTeam,
    employmentStatus,
    isIntern,
  } = params;
  const { adId, dmaId, itoId } = teamIds;

  if (
    !salesProfileGoogleDriveId ||
    !salesProfileLastModified ||
    !operativIds.includes(employmentStatus.id) ||
    isIntern ||
    ![adId, dmaId, itoId].includes(mainTeam.id)
  ) {
    return false;
  }
  return differenceInMonths(new Date(), salesProfileLastModified as Date) > THRESHOLD_MONTHS;
}

export const isEmployeeAway = (intervals: EmployeeAbsenceInterval[]): boolean => {
  const foundInterval = intervals.find((interval) =>
    isWithinInterval(new Date(), {
      start: startOfDay(parseDate(interval.start as Date)),
      end: endOfDay(parseDate(interval.end as Date)),
    }),
  );

  return !!foundInterval;
};
